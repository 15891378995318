import React, { useState, useEffect } from 'react';
import './OurBlog.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OurBlog = () => {
  const { t, i18n } = useTranslation();
  let language = '';
  if (i18n.language === 'es') {
    language = 'es';
  } else if (i18n.language === 'en') {
    language = 'en';
  } else {
    language = navigator.language.split('-')[0];
  }

  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [postImages, setPostImages] = useState({});
  const [visiblePosts, setVisiblePosts] = useState(2); // Number of posts to display initially

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('API URL:', process.env.REACT_APP_API_URL);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/blog-posts`);
        if (!response.ok) {
          throw new Error(`Failed to fetch blog posts. Status: ${response.status}`);
        }
        const data = await response.json();
        if (!Array.isArray(data)) {
          throw new Error('Unexpected data format: Expected an array.');
        }
        setBlogPosts(data); // Set blog posts

        // Fetch images only after posts have been set
        fetchImages(data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        alert('Failed to load blog posts.');
      }
    };

    fetchData();
  }, []);

  const fetchImages = async (posts) => {
    const imagePromises = posts.map(async (post) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/post-image/${post._id}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch image for post ${post._id}`);
        }
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        return { id: post._id, url: imageObjectURL };
      } catch (error) {
        console.error(`Error fetching image for post ${post._id}:`, error);
        return { id: post._id, url: null }; // Return null if there's an error fetching the image
      }
    });

    const images = await Promise.all(imagePromises);

    const imagesMap = images.reduce((acc, { id, url }) => {
      acc[id] = url;
      return acc;
    }, {});

    setPostImages(imagesMap);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const languageToSend = i18n.language === 'es' ? 'Spanish' : 'English';
      console.log(newsletterEmail);
      console.log(languageToSend);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newsletterEmail, language: languageToSend }),
      });

      if (response.ok) {
        setIsSubscribed(true);
      } else {
        const errorText = await response.text();
        alert('Failed to subscribe: ' + errorText);
      }
    } catch (error) {
      console.error('Error during subscription:', error);
      alert('Failed to subscribe. Please try again.');
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const loadMorePosts = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 2); // Load 2 more posts
  };

  return (
    <div className="blog-section">
      <div className="newsletter-signup">
        {isSubscribed ? (
          <h3>{t('our_blog.subscribe_confirmation')}</h3>
        ) : (
          <>
            <h3>{t('our_blog.subscribe_text')}</h3>
            <form onSubmit={handleSubscribe}>
              <input
                type="email"
                placeholder={t('our_blog.email_enter')}
                value={newsletterEmail}
                onChange={(e) => setNewsletterEmail(e.target.value)}
                required
              />
              <button type="submit">{t('our_blog.subscribe_button')}</button>
            </form>
          </>
        )}
      </div>
      {
        blogPosts.length > 0 ? (
          blogPosts
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort posts by date
            .slice(0, visiblePosts) // Show only the number of visible posts
            .map((post) => {
              console.log(`Post ID: ${post._id}, Image URL: ${postImages[post._id]}`);
              return (
                <div key={post._id} className="blog-post">
                  {postImages[post._id] ? (
                    <img src={postImages[post._id]} alt={language === 'es' ? post.title_es : post.title_en} />
                  ) : (
                    <p>{t('our_blog.no_image')}</p>
                  )}
                  <div className="blog-content">
                    <h3>{language === 'es' ? post.title_es : post.title_en}</h3>
                    <p>{language === 'es' ? post.subtitle_es : post.subtitle_en}</p>
                    <p>{formatDate(post.createdAt)}</p>
                    <Link to={`/pages/${post.label}`} target="_blank" rel="noopener noreferrer">{t('our_blog.read_more')}</Link>
                  </div>
                </div>
              );
            })
        ) : (
          <p>{t('our_blog.no_posts')}</p>
        )
      }
      {visiblePosts < blogPosts.length && (
        <button className="load-more-button" onClick={loadMorePosts}>{t('our_blog.load_more')}</button>
      )}
    </div>
  );
};

export default OurBlog;