// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import futureGoalsImage from '../images/future-goals.jpg';
import ethicsImage from '../images/ethics.jpg';
import collaborationImage from '../images/collaboration.jpg';

const AboutUs = () => {
  const { t } = useTranslation();


  return (
    <div className="about-us-container">
      <section className="mission">
        <div className="oval-container">
          <h2>{t('about_us.mission_title')}</h2>
          <p>{t('about_us.mission_text')}</p>
        </div>
      </section>

      <section className="future-goals">
        <h2>{t('about_us.future_goals_title')}</h2>
        <div className="row centered future-goals-row">
          <div className="text">
            <p>{t('about_us.future_goals_text')}</p>
          </div>
          <div className="image">
            <img src={futureGoalsImage} alt="Future Goals" />
          </div>
        </div>
      </section>

      <section className="ethics">
        <h2>{t('about_us.future_ethics_title')}</h2>
        <div className="row centered ethics-row">
          <div className="image">
            <img src={ethicsImage} alt="Ethics" />
          </div>
          <div className="text">
            <p>{t('about_us.future_ethics_text')}</p>
          </div>
        </div>
      </section>

      <section className="collaboration">
        <h2>{t('about_us.future_collaboration_title')}</h2>
        <div className="row centered collaboration-row">
          <div className="text">
            <p dangerouslySetInnerHTML={{ __html: t('about_us.future_collaboration_text') }}></p>
          </div>
          <div className="image">
            <img src={collaborationImage} alt="Collaboration" />
          </div>
        </div>
      </section>

      <section className="technology-overview blog-section">
        <div className="text">
          <h2>
            <Trans i18nKey="about_us.check_blog">
              Do you want to see our latest news? Take a look at <Link to="/about/our-blog" className="underline">our blog</Link>
            </Trans>
          </h2>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;