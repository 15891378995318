// src/pages/CaseStudies.js
import React, { useState } from 'react';
import './CaseStudies.css';
import biosimilarOptimizationPDF1 from '../pdfs/in_silico_optimization.pdf';
import biosimilarOptimizationPDF1Es from '../pdfs/in_silico_optimization_es.pdf';
import deNovoDesignPDF from '../pdfs/in_silico_design_report.pdf';
import deNovoDesignPDFEs from '../pdfs/de_novo_optimization_es.pdf';
import { useTranslation } from 'react-i18next';
import techOverviewImageEN from '../images/tech-overview.png';  // Pre-import English image
import techOverviewImageES from '../images/tech-overview-es.jpg'; // Pre-import Spanish image

const CaseStudies = () => {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('our_last_results');

  let language = '';
  if (i18n.language === 'es') {
    language = 'es';
  } else if (i18n.language === 'en') {
    language = 'en';
  } else {
    language = navigator.language.split('-')[0];
  }

  // Select the correct image based on the language
  const techOverviewImage = language === 'es' ? techOverviewImageES : techOverviewImageEN;

  const biosimilarInsilicoPDF = language === 'es' ? biosimilarOptimizationPDF1Es : biosimilarOptimizationPDF1;
  const denovoInsilicoPDF = language === 'es' ? deNovoDesignPDFEs : deNovoDesignPDF;

  return (
    <div className="case-studies-container">
      <div className="tab-selector">
        <button
          className={selectedTab === 'our_approach' ? 'tab active' : 'tab'}
          onClick={() => setSelectedTab('our_approach')}
        >
          {t('case_studies.our_approach')}
        </button>
        <button
          className={selectedTab === 'our_last_results' ? 'tab active' : 'tab'}
          onClick={() => setSelectedTab('our_last_results')}
        >
          {t('case_studies.our_last_results')}
        </button>
      </div>

      {selectedTab === 'our_approach' && (
        <div className="tab-content">
          <section className="technology-overview">
            <h2>{t('about_us.technology_overview_title')}</h2>
            <div className="text">
              <p>{t('about_us.technology_overview_text')}</p>
            </div>
            <div className="image">
              <img src={techOverviewImage} alt="Technology Overview" />
            </div>
          </section>

          <section className="applications">
            <h2>{t('about_us.applications')}</h2>
            <div className="applications-container">
              {/* Application categories */}
              {[
                'therapeutics', 'diagnostics', 'research_tools', 'vaccine_development', 
                'agricultural_improvements', 'veterinary', 'cosmetics', 'food', 
                'environment', 'biodefense', 'industrial', 'immunoassays'
              ].map(category => (
                <div key={category} className="application-category">
                  <h3>{t(`about_us.applications_${category}`)}</h3>
                  <p>{t(`about_us.applications_${category}_examples`)}</p>
                </div>
              ))}
            </div>
          </section>
        </div>
      )}

      {selectedTab === 'our_last_results' && (
        <div className="tab-content">
          <div className="case-study-block">
            <a href={biosimilarInsilicoPDF} target="_blank" rel="noopener noreferrer" className="case-study-title">
              {t('case_studies.optimization_insilico')}
            </a>
            <p className="case-study-description">{t('case_studies.optimization_explanation')}</p>
          </div>
          <div className="case-study-block">
            <a href={denovoInsilicoPDF} target="_blank" rel="noopener noreferrer" className="case-study-title">
              {t('case_studies.denovo_insilico')}
            </a>
            <p className="case-study-description">{t('case_studies.denovo_explanation')}</p>
          </div>
          <div className="case-study-block">
            <h2 className="case-study-title">{t('case_studies.our_pipeline_title')}</h2>
            <p className="case-study-description">
              {t('case_studies.our_pipeline_description')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseStudies;
